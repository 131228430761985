<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="$v.form.isbn.$model"
            :error-messages="getErrors('form.isbn')"
            label="ISBN" 
            name="isbn" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-select
          v-model="$v.form.subject.$model"
          :error-messages="getErrors('form.subject')"
          required
          :items="subjects"
          label="Предмет"
        ></v-select>

        <v-select
          v-model="$v.form.grade.$model"
          :error-messages="getErrors('form.grade')"
          required
          :items="activeGrades"
          label="Класс"
        ></v-select>

        <v-text-field 
            v-model.number="$v.form.start.$model"
            :error-messages="getErrors('form.start')"
            label="Начальный номер" 
            name="start" 
            type="number" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model.number="$v.form.end.$model"
            :error-messages="getErrors('form.end')"
            label="Конечный номер" 
            name="end" 
            type="number" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-divider class="my-4" />

        <v-row justify="start" align="center">
            <v-col class="col-auto">
                <v-btn 
                    v-if="!waiting"
                    dark 
                    outlined 
                    :color="$const.color.primary" 
                    small
                    @click.prevent.stop="back"
                >
                    <v-icon left dark>
                        mdi-arrow-left
                    </v-icon>
                    Назад к списку
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="col-auto">
                <v-btn 
                    dark 
                    :color="$const.color.primary"
                    small
                    :loading="waiting" 
                    @click.prevent.stop="assign"
                >
                    Сгенерировать
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ShortCodeAssignForm',
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            form: {
                isbn: '',
                subject: '',
                grade: '',
                start: '',
                end: ''
            },
            waiting: false
        };
    },
    computed: {
        ...mapState('license_type', {
            subjects: (state) => _.filter(state.subjects, subject => subject.value !== '*')
        }),
        ...mapGetters('license_type', {
            allGrades: 'grades'
        }),
        grades() {
            return _.filter(this.allGrades, grade => grade.value !== '*');
        }, 
        activeSubject() {
            return _.find(this.subjects, {value: _.get(this, 'form.subject')});
        },
        activeGrades() {
            const hasAll = _.includes(_.isArray(this.form.grade) ? this.form.grade : [this.form.grade], '*');
            return _.map(this.grades, (grade) => {
                if(hasAll)
                    grade['disabled'] = grade.value !== '*';
                else if(grade.value === '*')
                    grade['disabled'] = false;
                else
                    grade['disabled'] = !_.includes(_.get(this, 'activeSubject.grades', []), grade.value);
                return grade;
            })
        },
    },
    validations() {
        return {
            form: {
                isbn: {
                    required
                },
                subject: {
                    required
                },
                grade: {
                    required
                },
                start: {
                    required,
                    pattern: function(value) {
                        return value >= 1;
                    }
                },
                end: {
                    required,
                    pattern: function(value) {
                        return value >= 1 && value > this.form.start;
                    }
                }
            }
        }
    },
    methods: {
        async assign() {
            this.waiting = true;
            this.serverErrors = null;

            if (this.validate()) {
                const { success, error, data } = await this.$store.dispatch(`short_code/assign`, {
                    ...this.form
                });

                if(success)
                {
                    this.$emit('success', data);
                    this.back();
                    return ;
                }
                else
                    this.serverErrors = error;
            }

            this.waiting = false;
            return;
        },
    },
    watch: {
        ['form.subject']: {
            handler () {
                // if(!this.skip_subject_watch && _.get(this, 'form.data.grade', null) !== '*')
                 _.set(this, 'form.grade', null);

                // this.skip_subject_watch = false;
            },
            deep: true
        }
    },
}
</script>
<style scoped lang="scss">

</style>