<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Назначение ISBN"
        create
        url-back="/short_code"
    >
        <short-code-assign-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import ShortCodeAssignForm from '@/components/forms/ShortCodeAssignForm.vue'
export default {
    name: 'ShortCodeAssign',
    components: { FormView, ShortCodeAssignForm }
}
</script>